<template>
  <m-main-layout ref="main">
    <top-buttons slot="top-button" @toEdit="toEdit"></top-buttons>
<!--    <div slot="right-btn">-->
<!--      <el-button>fdf</el-button>-->
<!--    </div>-->
<!--    <div slot="right-btn"-->
<!--         v-if="params && (params.showOutput || (params.rightBottons && params.rightBottons.length > 0))">-->
<!--      <add-button size="mini" label="导出所有用户" @click.native="output" v-if="params && params.showOutput"></add-button>-->
<!--      <template v-if="params && params.rightBottons && params.rightBottons.length > 0"-->
<!--                v-for="(item,index) in params.rightBottons">-->
<!--        <base-button :type="item.type" :icon="item.icon" @click.native="toCall(item.path,item.data)">{{ item.label }}-->
<!--        </base-button>-->
<!--      </template>-->
<!--      <el-button>fdf</el-button>-->
<!--    </div>-->
<!--    <div class="margin-bottom">-->
<!--      <add-button size="mini" @click.native="toEdit(null)" :rule="params.addRole" v-if="listOptions.showAdd"></add-button>-->
<!--      <base-button @click.native="toExport(listOptions.exportUrl)" size="small" type="warning" v-if="listOptions.showExport && list.length > 0">导出报表</base-button>-->
<!--    </div>-->
    <div class="padding-lr-10 padding-bottom" slot="search" v-if="listOptions.showSearch">
      <el-row :gutter="10">
        <el-form :model="searchForm" label-width="120px" size="small">
          <el-col :xl="item.type === 'timeChoose' ? 12 : 6" :lg="item.type === 'timeChoose' ? 12 : 6"
                  :md="item.type === 'timeChoose' ? 16 : 12" :sm="item.type === 'timeChoose' ? 24 : 12" :xs="24"
                  v-for="(item,index) in params['search']['params']">
            <el-form-item :label="item.name" :label-width="item.name ? '120px' : '0px'">
              <type-select v-if="item.type === 'select'" v-model="searchForm[item.value]"
                           @change="(e)=>$set(searchForm,item.value,e)" :options="item.options ? item.options : null"
                           :data="item.data ? item.data : null" :url="item.url" :canAdd="false" style="width: 100%;"
                           :method="item.method" :val-key="item.valKey" :val-label="item.valLabel"></type-select>
              <member-level-select v-model="searchForm[item.value]" v-else-if="item.type === 'memberSelect'"></member-level-select>
              <year-select v-model="searchForm.year" v-else-if="item.value === 'year'" style="width: 100%;"></year-select>
              <area-select v-model="searchForm.province_code" v-else-if="item.type === 'area'" style="width: 100%;"></area-select>
              <sort-select v-model="searchForm.sort" v-else-if="item.type === 'sort'" style="width: 100%;"></sort-select>
              <order-type-select v-model="searchForm.order_type" v-else-if="item.type === 'orderType'"></order-type-select>
              <order-state-select v-model="searchForm.state" v-else-if="item.type === 'orderState'"></order-state-select>
              <range-time v-model="searchForm[item.value]" v-else-if="item.type ==='timeRange'" style="width: 100%;" @change="(e)=>changeRangeTime(e,item.columns,item.unix ? item.unix : '')"></range-time>
              <choose-time v-model="searchForm[item.value]" style="width: 100%;"
                           @change="(e)=>changeChooseTime(e,item.columns)"
                           v-else-if="item.type === 'timeChoose'"></choose-time>
              <el-select v-model="searchForm[item.value]" :placeholder="item.placeholder ? item.placeholder : '-请选择-'" v-else-if="item.type === 'status'">
                <el-option label="启用" value="0"></el-option>
                <el-option label="禁用" value="1"></el-option>
              </el-select>
              <ywlx-select v-model="searchForm[item.value]" v-else-if="item.type === 'ywlx'"></ywlx-select>
              <el-input v-model="searchForm[item.value]" class="block" :placeholder="item.placeholder" v-else></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="4" :lg="4" :md="6" :sm="24">
            <el-row :gutter="10" v-if="$isMobile">
              <el-col :span="12">
                <el-button type="primary" class="w100" size="small" @click="toSearch">搜索</el-button>
              </el-col>
              <el-col :span="12">
                <el-button type="info" class="w100" size="small" @click="toReset">重置</el-button>
              </el-col>
            </el-row>
            <template v-else>
              <el-button type="primary" size="small" @click="toSearch">搜索</el-button>
              <el-button type="info" size="small" @click="toReset">重置</el-button>
            </template>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div class="bg-light">
      <el-row>
        <el-col :span="4" v-if="listOptions.showDept">
          <dept-tree-side @change="changeDept" :id="searchForm.deptId"></dept-tree-side>
        </el-col>
        <el-col :span="listOptions.showDept ? 20 : 24">
          <div v-if="list.length > 0">
            <el-table
              stripe
              highlight-current-row
              style="width: 100%;"
              :show-summary="listOptions.apiTotal || listOptions.total"
              :summary-method="(listOptions.apiTotal && listOptions.apiTotalUrl) ? summaryMethod : null"
              :data="list"
              :row-key="listOptions.pk ? listOptions.pk : 'id'"
              :tree-props="{children: listOptions.listTree ? 'children' : ''}" :indent="listOptions.listTree ? 30 : 0"
              ref="listtable" :fit="true" @selection-change="selectionChange">
              <el-table-column fixed type="selection" width="70" v-if="params.showListDel">
              </el-table-column>
              <el-table-column
                fixed
                type="index"
                label="序号"
                width="50" v-if="listOptions.showListIndex" align="center">
              </el-table-column>
              <template v-for="(item,index) in listParams['params']">
                <el-table-column v-if="item.tem" :label="item.name" :min-width="item.fixed ? '' : (item.width ? item.width : '')" :width="item.fixed ? (item.width ? item.width : '') : ''" :fixed="item.fixed">
                  <template slot-scope="scope">
                    <template v-for="(item2,index2) in item.tem">
                      <div v-if="item2.type === 'copy'">
                        {{ item2.name ? item2.name + '：' : '' }}{{ item2.pre ? item2.pre : '' }}{{ $utils.evalGet(scope.row, item2.val) }}
                        <el-button size="mini" @click="copy((item2.pre ? item2.pre : '') + scope.row[item2.val])"
                                   class="margin-left">复制
                        </el-button>
                      </div>
                      <div
                        v-else-if="item2.val === 'title' && (scope.row.is_tuijian === 1 || scope.row.is_toutiao === 1 || scope.row.sort > 0)">
                        <div class="margin-bottom">
                          <el-tag size="mini" effect="dark" type="danger" class="margin-right"
                                  v-if="scope.row.is_tuijian">推荐
                          </el-tag>
                          <el-tag size="mini" effect="dark" type="warning" v-if="scope.row.is_toutiao"
                                  class="margin-right">头条
                          </el-tag>
                          <el-tag size="mini" effect="dark" type="success" v-if="scope.row.sort === 3">一级置顶</el-tag>
                          <el-tag size="mini" effect="dark" type="success" v-if="scope.row.sort === 2">二级置顶</el-tag>
                          <el-tag size="mini" effect="dark" type="success" v-if="scope.row.sort === 1">三级置顶</el-tag>
                        </div>
                        <div>
                          {{ item2.name ? item2.name + '：' : '' }}{{ item2.pre ? item2.pre : '' }}{{ $utils.evalGet(scope.row, item2.val) }}
                        </div>
                      </div>
                      <div v-else-if="item2.type === 'tag'">
                        <el-tag v-for="(item,index) in scope.row[item2.val]" class="margin-right hand"
                                @click="copy(item)">{{ item }}
                        </el-tag>
                      </div>
                      <div v-else>
                        {{ item2.name ? item2.name + '：' : '' }}{{ item2.pre ? item2.pre : '' }}{{ $utils.evalGet(scope.row, item2.val) }}
                      </div>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column v-else-if="item.value === 'title'" :label="item.name" :min-width="item.width ? item.width : ''">
                  <template slot-scope="scope">
                    <div class="margin-bottom">
                      <el-tag size="mini" effect="dark" type="danger" class="margin-right" v-if="scope.row.is_tuijian">
                        推荐
                      </el-tag>
                      <el-tag size="mini" effect="dark" type="warning" v-if="scope.row.is_toutiao">头条</el-tag>
                    </div>
                    {{ scope.row.title }}
                  </template>
                </el-table-column>
                <template v-else>
                  <el-table-column :label="item.name" :prop="item.value" v-if="item.type === 'state'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <el-tag :type="scope.row[item.value] === 1 ? 'success' : 'danger'" disable-transitions>
                        {{ scope.row[item.value] === 1 ? '可用' : '禁用' }}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'check'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <el-tag type="success" disable-transitions v-if="scope.row.state === 1">审核通过</el-tag>
                      <el-tag type="warning" disable-transitions v-if="scope.row.state === 0">待审核</el-tag>
                      <el-tag type="danger" disable-transitions v-if="scope.row.state === 2">审核不通过</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'icon'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <i :class="scope.row[item.value]"></i>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'bool'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <el-tag :type="scope.row[item.value] === 1 ? 'success' : 'danger'" disable-transitions>
                        {{ scope.row[item.value] === 0 ? '否' : '是' }}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'custom-select'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <el-tag :type="st.color" disable-transitions v-for="(st,si) in item.options" v-if="scope.row[item.value] === st.val">{{ st.label }}</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'sex'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <span v-if="scope.row[item.value] === 0">未知</span>
                      <span v-if="scope.row[item.value] === 1">男</span>
                      <span v-if="scope.row[item.value] === 2">女</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'percent'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      {{ (scope.row[item.value] * 100).toFixed(2) }}%
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'copy'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <div>
                        {{ item.name ? item.name + '：' : '' }}{{ item.root ? $utils.evalGet(scope.row, item.value) : scope.row[item.value] }}
                        <el-button size="mini" @click="copy(item.root ? $utils.evalGet(scope.row,item.value) : scope.row[item.value])" class="margin-left">复制
                        </el-button>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'image'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <el-image style="height: 60px"
                                :src="scope.row[item.value].indexOf('http')>= 0 ? scope.row[item.value] : $config.assetsUrl + scope.row[item.value]"
                                :preview-src-list="scope.row[item.value].indexOf('http')>= 0 ? [scope.row[item.value]] : [$config.assetsUrl + scope.row[item.value]]"
                                v-if="scope.row[item.value]">
                      </el-image>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'base64image'"
                                   :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <el-image style="height: 60px"
                                :src="'data:image/jpeg;base64,'+scope.row[item.value]"
                                :preview-src-list="['data:image/jpeg;base64,'+scope.row[item.value]]"
                                v-if="scope.row[item.value]">
                      </el-image>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'totime'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <div v-if="scope.row.time_type === 'day'">{{ scope.row.to_time }}天</div>
                      <div v-if="scope.row.time_type === 'week'">{{ scope.row.to_time }}周</div>
                      <div v-if="scope.row.time_type === 'month'">{{ scope.row.to_time }}月</div>
                      <div v-if="scope.row.time_type === 'year'">{{ scope.row.to_time }}年</div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'orderState'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <div v-if="scope.row[item.value] === '0'">待支付</div>
                      <div v-if="scope.row[item.value] === '1'">已支付</div>
                      <div v-if="scope.row[item.value] === '2'">已关闭</div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'payType'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <div v-if="scope.row[item.value] === '121'">微信</div>
                      <div v-if="scope.row[item.value] === '016'">支付宝</div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'orderType'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <div v-if="scope.row[item.value] === 'vip'">购买VIP</div>
                      <div v-if="scope.row[item.value] === 'bids'">购买标讯</div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'time'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      {{ scope.row[item.value] > 0 ? $moment.unix(scope.row[item.value]).format('YYYY-MM-DD HH:mm:ss') : '-' }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'unixtime'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      {{ scope.row[item.value] > 0 ? $moment(scope.row[item.value]).format('YYYY-MM-DD HH:mm:ss.SSS') : '-' }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'link'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <a :href="item.linkUrl ? item.linkUrl+scope.row[item.value] : scope.row[item.value]"
                         :target="item.target ? item.target : '_blank'">{{ scope.row[item.value] }}</a>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'html'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <div class="cl rel">
                        <div class="z" :class="item.more ? 'text-line-2' : ''" :style="item.more ? 'margin-left:25px;' : ''" v-html="item.root ? $utils.evalGet(scope.row,'scope.row',item.value) : scope.row[item.value]"></div>
                        <el-button type="info" icon="el-icon-view" circle @click="showMore(scope.row[item.value])" v-if="item.more && scope.row[item.value]" size="mini" class="abs" style="top:0;left:0;padding:2px !important;"></el-button>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'changeStatus'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <el-switch
                        v-model="scope.row[item.value]"
                        active-color="#13ce66"
                        :active-value="1"
                        :inactive-value="0"
                        @change="changeStatus(scope.row[listOptions.pk ? listOptions.pk : 'id'],scope.row)">
                      </el-switch>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="item.width ? item.width : ''" :label="item.name" :prop="item.value" v-else-if="item.type === 'mobile'">
                    <template slot-scope="scope">
                      {{ scope.row[item.value] ? scope.row[item.value].substr(0, 3) + '****' + scope.row[item.value].substr(-4, 4) : '-' }}
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="item.width ? item.width : ''" :label="item.name" :prop="item.value" v-else-if="item.type === 'idCard'">
                    <template slot-scope="scope">
                      {{ scope.row[item.value] ? scope.row[item.value].substr(0, 7) + '********' : '-' }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'price'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      {{ (parseFloat(scope.row[item.value]) / 100).toFixed(2) }}
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'options'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <template v-for="(t,i) in item.options">
                        <span v-if="scope.row[item.value] === t.value">{{ t.label }}</span>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column :label="item.name" :prop="item.value" v-else-if="item.type === 'ywlx'" :min-width="item.width ? item.width : ''">
                    <template slot-scope="scope">
                      <div>{{ $utils.ywlx(scope.row[item.value]) }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="item.width ? item.width : ''" :fixed="item.fixed" :label="item.name" :prop="item.value" :formatter="(row, column, cellValue, index)=>{return cellValue ? cellValue : '-'}" v-else></el-table-column>
                </template>
              </template>
              <el-table-column fixed="right" align="right" label="操作" :width="params.optionsWidth ? params.optionsWidth : (listOptions.showDetail ? 300 : 200)" v-if="listOptions.showAdd || listOptions.showEdit || listOptions.showDel || listOptions.showDetail || (listParams.extButtons && listParams.extButtons.length > 0)">
                <template slot-scope="scope">
                  <ext-buttons :scope-row="scope.row" @toExt="toExt" @toCall="toCall" @toEdit="toEdit" @toDetail="toDetail" @success="getList"></ext-buttons>
<!--                  <base-button type="text" @click.native="toDetail(scope.row)" :rule="params.detailRole" v-if="listOptions.showDetail" icon="el-icon-view">-->
<!--                    {{ listOptions.detailText ? listOptions.detailText : '详情' }}-->
<!--                  </base-button>-->
<!--                  <edit-button type="text" @click.native="toEdit(scope.row)" v-if="listOptions.showEdit" :rule="params.editRole"></edit-button>-->
<!--                  <del-button type="text" @click.native="del(listOptions.pk ? scope.row[listOptions.pk] : scope.row.id)" :rule="params.delRole" v-if="listOptions.showDel"></del-button>-->
<!--                  <template v-if="listOptions.dropExtButton">-->
<!--                    <el-dropdown class="margin-left hand" v-if="listParams['extButtons']">-->
<!--                      <span class="el-dropdown-link">更多操作<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i></span>-->
<!--                      <el-dropdown-menu slot="dropdown">-->
<!--                        <base-drop-item v-for="(item,index) in listParams['extButtons']" :rule="item.rule ? item.rule : ''" v-if="item.rules ? $utils.callOperator(scope.row[item.rules.key],item.rules.val,item.rules.operator ? item.rules.operator : '===') : true" @click.native="$router.push({path:item.path,query:{[item.key ? item.key : 'id']:scope.row.id}})">-->
<!--                          {{ item.label }}-->
<!--                        </base-drop-item>-->
<!--                      </el-dropdown-menu>-->
<!--                    </el-dropdown>-->
<!--                  </template>-->
<!--                  <template v-else>-->
<!--                    <ext-buttons :list="listParams['extButtons']" :scope-row="scope.row" :pk="listOptions.pk" @toExt="toExt" @toCall="toCall"></ext-buttons>-->
<!--                    <template v-for="(item,index) in listParams['extButtons']">-->
<!--                      <template v-if="item.type === 'fun'">-->
<!--                        <base-button :class="item.color ? 'text-button-'+item.color : ''" type="text" :rule="item.role ? item.role : ''" v-if="item.rules ? $utils.callOperator(scope.row[item.rules.key],item.rules.val,item.rules.operator ? item.rules.operator : '===') : true" :icon="item.icon" @click.native="toCall(item.path,{[listOptions.pk ? listOptions.pk : 'id']:scope.row[listOptions.pk ? listOptions.pk : 'id']})">{{ item.label }}</base-button>-->
<!--                      </template>-->
<!--                      <template v-if="item.type === 'url'">-->
<!--                        <base-button :class="item.color ? 'text-button-'+item.color : ''" type="text" :rule="item.role ? item.role : ''" v-if="item.rules ? $utils.callOperator(scope.row[item.rules.key],item.rules.val,item.rules.operator ? item.rules.operator : '===') : true" :icon="item.icon" @click.native="$router.push({path:item.path,query:{[item.key ? item.key : 'id']:scope.row.id}})">{{ item.label }}</base-button>-->
<!--                      </template>-->
<!--                      <template v-else-if="item.type === 'dialog'">-->
<!--                        <base-button :class="item.color ? 'text-button-'+item.color : ''" type="text" :rule="item.role ? item.role : ''" v-if="item.rules ? $utils.callOperator(scope.row[item.rules.key],item.rules.val,item.rules.operator ? item.rules.operator : '===') : true" :icon="item.icon" @click.native="toExt(scope.row,item)">{{ item.label }}</base-button>-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        <base-button :class="item.color ? 'text-button-'+item.color : ''" type="text" @click.native="$router.push({path:item.path,query:{[item.key ? item.key : 'id']:scope.row.id}})" :rule="item.role ? item.role : ''" v-if="item.rules ? $utils.callOperator(scope.row[item.rules.key],item.rules.val,item.rules.operator ? item.rules.operator : '===') : true" :icon="item.icon">-->
<!--                          {{ item.label }}-->
<!--                        </base-button>-->
<!--                      </template>-->
<!--                    </template>-->
<!--                  </template>-->
                </template>
              </el-table-column>
            </el-table>
            <div class="margin-top" v-if="params.showListDel || params.showListTuijian || params.showListToutiao">
              <base-button @click.native="selectAll" :rule="params.delRouter">全选</base-button>
              <del-button @click.native="listDel" :rule="params.delRouter" label="批量删除"
                          v-if="params.showListDel"></del-button>
              <base-button icon="el-icon-close-notification" type="danger"
                           @click.native="listChangeState('is_tuijian','推荐')" :rule="params.editRouter"
                           v-if="params.showListTuijian">批量取消推荐
              </base-button>
              <base-button icon="el-icon-close-notification" type="primary"
                           @click.native="listChangeState('is_toutiao','头条')" :rule="params.editRouter"
                           v-if="params.showListToutiao">批量取消头条
              </base-button>
              <base-button icon="el-icon-close-notification" type="success" @click.native="listChangeState('sort','置顶')" :rule="params.editRouter" v-if="params.showListSort">批量取消置顶</base-button>
              <base-button icon="el-icon-close-notification" type="primary" plain
                           @click.native="listChangeState('state','审核')" :rule="params.checkRouter"
                           v-if="params.showListState">批量重置审核
              </base-button>
            </div>
            <div class="padding-tb-20 text-center" v-if="showPage">
              <el-pagination
                :hide-on-single-page="$isMobile"
                background
                :layout="$isMobile ? 'total,prev,next' : 'total,sizes,prev, pager, next'"
                :page-sizes="[5,10, 50, 100, 200]"
                @size-change="changeSize"
                :page-size="searchForm[listOptions.pageSizeKey ? listOptions.pageSizeKey : 'pageSize']"
                :current-page.sync="searchForm[listOptions.pageKey ? listOptions.pageKey : 'page']"
                :total="totalCount"
                @current-change="pageChange">
              </el-pagination>
            </div>
          </div>
          <div class="padding-tb-40 border-top text-center dark-2" v-else>
            暂无数据
            <!--                      <add-button @click.native="$router.push({path:params.editRouter})" :rule="params.addRole" v-if="listOptions.showAdd"></add-button>-->
          </div>
        </el-col>
      </el-row>
    </div>
    <component :is="EditComponent" :path="path" :id="editId" :title="editOptions.title" v-model="showAddDialog" v-if="showAddDialog" @success="getList"></component>
    <component :is="ExtComponent" :id="extDialog.id" :params="extDialog.params" :info="extDialog.info" :list="list" :title="extDialog.title" v-model="showExtDialog" v-if="showExtDialog" @success="getList"></component>
    <component :is="DetailComponent" v-model="showDetailDialog" :title="detailOptions.title" :api-url="detailOptions.detailUrl" :pk="detailOptions.pk" :id="detailId" v-if="listOptions.showDetail" :method="detailOptions.method" :width="detailOptions.width ? detailOptions.width : '900px'"></component>
    <!--        <detail v-model="showDetailDialog" :info="detailInfo"></detail>-->
  </m-main-layout>
</template>

<script>

export default {
  name: "list_bak",
  data() {
    return {
      outputData: null,
      showAddDialog: false,
      showDetailDialog: false,
      showExtDialog:false,
      DetailComponent: {},
      EditComponent:{},
      ExtComponent:{},

      extDialog:{
        id:'',
        title:'',
        info:null,
        options:null
      },

      showPage:true,

      path: '',
      params: null,
      listParams: null,
      listOptions: null,

      detailOptions: null,
      detailId: '',

      totalCount: 0,
      list: [],
      searchForm: {},

      pageKey:'',
      pageSizeKey:'',

      addForm: {},
      editParams: null,
      editOptions: null,

      addParams: null,
      addOptions: null,

      editId: '',

      listData: null,
      addTotal: {},
      addTotalType: false,
      rules: {},
      time: null,
      loading: null,
      ids: [],
      outForm: {
        create_time: '',
        items: ["create_time", "name", "mobile", "email", "company", "job", "vip"]
      },
      outCount: 0,

      totalData: {}
    };
  },
  created() {
    //获取配置
    let _config = this.$baseConfig

    //获取当前路径
    this.path = this.$route.path;

    //获取路径全部配置
    this.params = _config[this.path];

    //获取编辑参数
    this.editParams = this.params.edit.params;

    //获取编辑配置
    this.editOptions = this.params.edit.options;

    //适配新增参数，如果没有，则去编辑参数
    this.addParams = this.params.add.params ? this.params.add.params : this.params.edit.params;
    //适配新增配置，如果没有，则去编辑配置
    this.addOptions = this.params.add.options ? this.params.add.options : this.params.edit.options;

    //获取列表参数
    this.listParams = this.params.list;
    //获取列表配置
    this.listOptions = this.params.list.options;

    //获取详情配置
    this.detailOptions = this.params.detail.options ? this.params.detail.options : null;

    //获取分页参数
    this.pageSizeKey = this.listOptions.pageSizeKey ? this.listOptions.pageSizeKey : 'pageSize';
    this.pageSize = this.listOptions.pageSize ? this.listOptions.pageSize : 10;
    this.pageKey = this.listOptions.pageKey ? this.listOptions.pageKey : 'pageNum';
    this.showPage = this.$utils.isUndefined(this.listOptions.showPage) ? true : this.listOptions.showPage;

    //初始化搜索参数
    if (this.listOptions.showSearch) {
      let _edit = this.params['search']['params'];

      for (let i = 0; i < _edit.length; i++) {
        if (_edit[i].type === 'timeRange') {
          this.$set(this.searchForm, _edit[i].value, '');
        } else {
          this.$set(this.searchForm, _edit[i].value, '');
        }
      }

      let _tabs = this.$user.getTabByPath(this.$route.path);

      this.searchForm = _tabs.data ? _tabs.data : {};

      this.$set(this.searchForm, this.pageSizeKey, this.searchForm[this.pageSizeKey] ? this.searchForm[this.pageSizeKey] : this.pageSize);
      this.$set(this.searchForm, this.pageKey, this.searchForm[this.pageKey] ? this.searchForm[this.pageKey] : 1);
    }

    //初始化详情组件
    if (this.listOptions.showDetail) {
      if (this.params.detail.com) {
        this.DetailComponent = () => import('@/views' + this.params.detail.com);
      } else {
        this.DetailComponent = () => import('@/views/base/detail');
      }
    }

    //初始化编辑组件
    if (this.listOptions.showEdit || this.listOptions.showAdd) {
      if (this.params.edit.com) {
        this.EditComponent = () => import('@/views' + this.params.edit.com);
      } else {
        this.EditComponent = () => import('@/components/dialog/EditForm');
      }
    }

    //获取页数
    this.searchForm[this.pageKey] = this.searchForm[this.pageKey] ? parseInt(this.searchForm[this.pageKey]) : 1;

    //获取分页数
    this.searchForm[this.pageSizeKey] = this.$route.query[this.pageSizeKey] ? parseInt(this.$route.query[this.pageSizeKey]) : (this.$user.getPage(this.$route.path) ? parseInt(this.$user.getPage(this.$route.path)) : this.pageSize);

    if (this.params.showDept) {
      // this.searchForm.deptId = '';
    }
  },
  mounted() {
    //获取列表
    this.getList();

    //是否显示统计，是则调用统计接口
    if (this.listOptions.apiTotal) {
      this.getTotal();
    }
  },
  methods: {
    //统计事件
    summaryMethod(param) {
      const {columns, data} = param;

      let sums = [];

      columns.forEach((column, index) => {
        for (let i = 0; i < this.listParams.total.length; i++) {
          if (this.listParams.total[i].prop === column.property) {
            sums[index] = this.totalData[this.listParams.total[i].value] + (this.listParams.total[i].dw ? this.listParams.total[i].dw : '');
          }
        }
      });

      return sums;
    },
    //统计接口
    getTotal() {
      let _this = this;

      let _datas = JSON.parse(JSON.stringify(this.searchForm));

      delete _datas.pageSize;
      delete _datas.pageNum;

      if (this.listOptions.apiTotalUrl) {
        this.$http.request({
          method: 'post',
          url: this.listOptions.apiTotalUrl,
          datas: {..._datas},
          success(res) {

            _this.totalData = res;
            console.log(res);
          }
        });
      }
    },
    //复制
    copy(txt) {
      let _this = this;
      this.$copyText(txt).then(function (e) {
        _this.$utils.success('复制成功');
      }, function (e) {

      })
    },
    //搜索
    toSearch() {
      for (let i in this.searchForm) {
        if (i.endsWith('_time')) {
          console.log(i);
        }
      }
      this.searchForm.pageNum = 1;
      // this.$router.replace({query:{...this.searchForm}});
      this.getList();

      if (this.listOptions.apiTotal) {
        this.getTotal();
      }

      this.$user.setTabData(this.$route.path, this.searchForm);

      if (this.$isMobile) {
        this.$refs.main.closeSearch();
      }
    },

    //重置搜索
    toReset() {
      this.searchForm[this.pageKey] = 1;

      for (let i in this.searchForm) {
        if (i !== this.pageSizeKey && i !== this.pageKey) {
          // if (typeof this.searchForm[i] === 'string' || typeof this.searchForm[i] === 'number') {
          this.$set(this.searchForm, i, '');
          // } else {
          //   this.$set(this.searchForm, i, '');
          // }
        }
      }

      this.getList();

      if (this.listOptions.apiTotal) {
        this.getTotal();
      }

      this.$user.setTabData(this.$route.path, this.searchForm);
    },

    //获取列表
    getList() {
      let _this = this;
      let _url = '';

      let _listData = {};

      if (this.listOptions) {

        _url = this.listOptions.listUrl ? this.listOptions.listUrl : '';

        _listData = this.listOptions.data ? this.listOptions.data : {};

        if (this.listOptions.showDept) {
          _listData.deptId = this.searchForm.deptId;
        }

        this.$http.request({
          method: this.listOptions.method ? this.listOptions.method : 'post',
          url: _url,
          datas: {..._this.searchForm, ..._listData},
          success(res) {

            _this.list = res[_this.listOptions.listKey] ? res[_this.listOptions.listKey] : (res ? res : []);

            if (_this.listOptions.showPage) {
              _this.totalCount = res.total;
            }
          }
        });
      }
    },
    pageChange(page) {
      this.searchForm[this.pageKey] = page;
      this.getList();
    },
    del(id) {
      let _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http.request({
            url: _this.listOptions.delUrl.replace('{id}', id),
            datas: {[_this.listOptions.pk ? _this.listOptions.pk : 'id']: id},
            method: _this.listOptions.delMethod ? _this.listOptions.delMethod : 'post',
            success(res) {
              _this.$utils.success("删除成功！");
              _this.getList();

              if (_this.listOptions.apiTotal) {
                _this.getTotal();
              }
            }
          });
        })
        .catch(() => {
          // _this.$utils.warning('取消了');
        });
    },
    output() {
      let _this = this;

      this.$http.request({
        url: _this.params.apiUrl + '/getOutPut',
        datas: null,
        success(res) {
          _this.outputData = res;
          _this.showOutputReset = true;

          _this.getOutCount();
        }
      });
    },
    reMark() {
      let _this = this;
      this.$http.request({
        url: _this.params.apiUrl + '/remark',
        datas: this.outForm,
        success(res) {
          _this.$utils.success('执行成功，请等待5分钟后再次点击导出按钮即可下载');
          _this.showOutputReset = false;
        }
      });
    },
    changeOutTime() {
      this.getOutCount();
    },
    getOutCount() {
      let _this = this;
      this.$http.request({
        url: _this.params.apiUrl + '/getOutPutCount',
        datas: this.outForm,
        success(res) {
          _this.outCount = res.count;
        }
      })
    },
    download(url) {
      window.open(url, '_blank');
      this.showOutputReset = false;
    },
    selectAll() {
      this.$refs.listtable.toggleAllSelection();
    },
    selectionChange(val) {
      let _check = [];
      if (val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          _check.push(val[i].id);
        }
      }

      this.ids = _check;
    },
    listChangeState(type, text) {
      let _this = this;
      if (this.ids.length > 0) {
        this.$confirm('是否批量改变' + this.ids.length + '条数据的' + text + '状态?', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            let _datas = {};

            _datas[type] = 0;

            this.$http.request({
              url: _this.listOptions.statusUrl,
              datas: {id: [this.ids, 'in'], ..._datas},
              success(res) {
                _this.$utils.success("操作成功！");
                _this.getList();

                if (_this.listOptions.apiTotal) {
                  _this.getTotal();
                }
              }
            });
          })
          .catch(() => {
            // _this.$utils.warning('取消了');
          });
      } else {
        this.$utils.warning('请选择要操作的数据');
      }
    },
    changeStatus(index, row) {
      let _this = this;
      let _text = '';
      let _status = '';
      let flag = row.status;

      console.log(row);

      if (row.status === 0 || row.status === 1) {
        if (row.status === 1) {
          _text = '是否【禁用】该条数据？';
          _status = 1;
        } else if (row.status === 0) {
          _text = '是否【启用】该条数据？';
          _status = 0;
        }
        this.$confirm(_text, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            if (_status === 0 || _status === 1) {
              if (_this.listOptions.statusUrl) {
                let _id = row[_this.listOptions.pk ? _this.listOptions.pk : 'id'];

                this.$http.request({
                  url: _this.params.list.options.statusUrl.replace('{id}', _id),
                  datas: {
                    [_this.listOptions.pk ? _this.listOptions.pk : 'id']: _id,
                    status: _status
                  },
                  method:'patch',
                  success(res) {
                    _this.$utils.success("操作成功！");
                    _this.getList();
                  },
                  fail(err){
                    if (flag === 1) {
                      row.status = 0;
                    } else {
                      row.status = 1;
                    }
                    _this.$utils.error(err.msg);
                  }
                });
              }
            }
          })
          .catch(() => {
            if (flag === 1) {
              row.status = 0;
            } else {
              row.status = 1;
            }
          });
      } else {
        this.$utils.warning('操作异常');
      }
    },
    listDel() {
      let _this = this;
      if (this.ids.length > 0) {
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.$http.request({
              url: _this.listOptions.delUrl,
              datas: {id: [this.ids, 'in']},
              success(res) {
                _this.$utils.success("删除成功！");
                _this.getList();

                if (_this.listOptions.apiTotal) {
                  _this.getTotal();
                }
              }
            });
          })
          .catch(() => {
            // _this.$utils.warning('取消了');
          });
      } else {
        this.$utils.warning('请选择要删除的数据');
      }
    },
    toRefund(path, data) {
      let _this = this;
      console.log(path, data);
      if (path && data) {
        this.$prompt('是否确认退款此订单？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[A-Za-z0-9_,.?？《》/！…….,￥!@#$%^&*()（）~`"':;，。、~”“：；【】『』「」\-\u4e00-\u9fa5]{1,100}$/,
          inputErrorMessage: '格式不正确',
          inputPlaceholder: '请输入退款原因(必填)'
        }).then(({value}) => {
          _this.$http.request({
            method: 'post',
            url: path,
            datas: {type: data.type, ywddh: data.ywddh, tkyy: value},
            success(res) {
              _this.$utils.success("操作成功！");
              _this.getList();
            },
            fail(err) {
              _this.$utils.error(err.msg);
              _this.getList();
            }
          });
        }).catch(() => {
        });
      } else {
        this.$utils.warning('缺少参数，无法操作');
      }
    },
    toCall(path, data) {
      let _this = this;
      if (path && data) {
        this.$http.request({
          url: path,
          datas: data,
          success(res) {
            _this.$utils.success("操作成功！");
            _this.getList();
          }
        });
      } else {
        this.$utils.warning('缺少参数，无法操作');
      }
    },
    changeDept(id) {
      this.$set(this.searchForm, 'deptId', id);

      this.getList();

      // this.$router.replace({query:{...this.searchForm}});
    },
    showMore(e) {
      let _this = this;
      this.$confirm(e, '详细内容', {
        customClass: 'alert-message',
        confirmButtonText: '关闭',
        cancelButtonText: '复制',
        closeOnClickModal: true,
        showCancelButton: false,
        closeOnPressEscape: true,
      }).then(() => {})
    },

    //改变分页数
    changeSize(e) {
      this.searchForm[this.pageKey] = 1;
      this.searchForm[this.pageSizeKey] = e;

      this.$user.setPage(this.$route.path, e);

      this.getList();
    },

    //查看详情
    toDetail(item) {
      this.showDetailDialog = true;
      this.detailId = item[this.detailOptions.pk ? this.detailOptions.pk : 'id'];
    },

    //打开自定义弹框
    toExt(data,options){
      if(options.com) {
        this.extDialog.id = data[this.listOptions.pk ? this.listOptions.pk : 'id'];
        this.extDialog.title = options.title ? options.title : '弹框';
        this.extDialog.info = data;
        this.extDialog.params = options.params;

        if (options.com) {
          this.ExtComponent = () => import('@/views' + options.com);
        }

        this.showExtDialog = true;
      }else{
        this.$message.error('缺少组件');
      }
    },

    //编辑
    toEdit(item) {
      if (item) {
        this.editId = item[this.editOptions.pk ? this.editOptions.pk : 'id'];
      } else {
        this.editId = '';
      }

      this.showAddDialog = true;
    },
    changeRangeTime(e, value, unix = '') {
      let _times = e.split(',');

      this.$set(this.searchForm, value[0], unix === 'sss' ? this.$moment(_times[0]).valueOf() : _times[0]);
      this.$set(this.searchForm, value[1], unix === 'sss' ? this.$moment(_times[1]).valueOf() : _times[1]);
    },
    changeChooseTime(e, value) {
      let _times = e.split(',');

      this.$set(this.searchForm, value[0], _times[0]);
      this.$set(this.searchForm, value[1], _times[1]);
    },

    //导出
    toExport(url) {
      let _this = this;

      if (url) {
        let _datas = JSON.parse(JSON.stringify(this.searchForm));
        delete _datas.pageSize;
        delete _datas.pageNum;

        this.$http.request({
          url: url,
          datas: _datas,
          method: 'get',
          download: true,
          success(res) {
            const blob = new Blob([res]) // 构造一个blob对象来处理数据
            const fileName = (_this.listOptions.exportFileName ? _this.listOptions.exportFileName : '导出数据') + '_' + _this.$moment().format('YYYYMMDDHHmmss') + '.xlsx';

            console.log(fileName);

            const link = document.createElement('a') // 创建a标签
            link.download = fileName // a标签添加属性
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click();
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        });
      }
    }
  },
  destroyed() {
    if (this.time) {
      clearInterval(this.time);
      this.time = null;
    }
  }
};
</script>
